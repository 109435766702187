<template>
  <div>
    <el-row>
      <el-col>
        <app-navigation :title="$t('security')"></app-navigation>
      </el-col>
    </el-row>
    <div class="layout-settings">

      <div class="form-title">{{ $t('settings.security.security-settings') }} <span class="save" v-if="showSaveButton"
          style="float: right; cursor: pointer" @click="onSave"><i class="fa fa-upload"></i> {{
            $t('settings.network.save') }}</span></div>

      <el-form ref="projectData" :model="formData" label-position="right" label-width="250px" class="form-settings">
        <el-form-item :label="$t('settings.security.arm-disarm-pwd')">
          <el-input style="width: 200px" :type="showArmDisarmPwd ? 'text' : 'password'"
            v-model="formData.armDisarmPassword" :placeholder="$t('settings.security.type-password')"
            v-mask="{ mask: '9999' }">
            <i :class="showArmDisarmPwd ? 'fa fa-lock' : 'fa fa-eye'" slot="append"
              @click="showArmDisarmPwd = !showArmDisarmPwd"></i>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('settings.security.integrator-pwd')">
          <el-input style="width: 200px" :type="showIntegratorPwd ? 'text' : 'password'"
            v-model="formData.integratorPassword" :placeholder="$t('settings.security.type-password')"
            v-mask="{ mask: '9999' }">
            <i :class="showIntegratorPwd ? 'fa fa-lock' : 'fa fa-eye'" slot="append"
              @click="showIntegratorPwd = !showIntegratorPwd"></i>
          </el-input>
        </el-form-item>

        <el-form-item v-show="false" :label="$t('settings.security.web-client-pwd')">
          <el-input style="width: 200px" type="text" v-model="formData.webPassword"
            :placeholder="$t('settings.security.type-password')"></el-input>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import { initializeWebSocket } from "@/utils/socket-utils"
export default {
  name: "Security",
  components: {
    'app-navigation': Navigation
  },

  data: function () {
    return {
      formData: {},
      showSaveButton: false,
      showArmDisarmPwd: false,
      showIntegratorPwd: false
    }
  },
  computed: {
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    formData: {
      handler: function (val) {
        this.showSaveButton = true

      },
      deep: true
    },
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    onSave() {
      this.$store.dispatch("saveSecuritySettings", this.formData).then(response => {

        this.$message({ type: "success", message: this.$t(response.message) })
        this.showSaveButton = false
      }).catch(reason => {
        this.$message({ type: "error", message: this.$t(reason) })
        this.showSaveButton = false
      })
    },
    async fetchData() {
      try {
        await this.$store.dispatch("getSecuritySettings");
        this.formData = { ...this.$store.getters.securitySettings }
      } catch (error) {
      }
    }
  },

  created() {
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  }
}
</script>

<style scoped>
@import "../css/general.css";

div i {
  font-size: medium;
  cursor: pointer;
}

div i:hover {
  color: #00BFD7;
}

div i:active {
  box-shadow: 0px 0px 5px #00BFD7;
  border-radius: 2px;
}</style>